import commerceConfig from '@commerce/config'
import { Breadcrumbs, Error, Layout, Loader, SeoHead } from '@components/common'
import AcknowledgementItem from '@components/common/AcknowledgementItem'
import AuxSidebar from '@components/common/AuxSidebar'
import MobileAuxSideBar from '@components/common/MobileAuxSidebar'
import { Plus } from '@components/icons'
import { Container, Sidebar } from '@components/ui'
import getGlobalStaticProps from '@lib/globalStaticProps'
import {
  AUX_PAGE_NAVIGATION_HANDLE,
  GENERAL_PAGE_SIDE_NAVIGATION_HANDLE,
  STOCKISTS_PAGE_NAVIGATION_HANDLE,
} from '@lib/helpers/general'
import { queryAll } from '@lib/prismicGraphQl/client'
import GET_ALL_GENERAL_PAGES_FOR_FEED from '@lib/prismicGraphQl/getAllGeneralPagesForFeed'
import { SliceZone } from '@prismicio/react'
import { components } from '@slices/index'
import cn from 'classnames'
import { AnimatePresence } from 'motion/react'
import type {
  GetStaticPathsContext,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { createClient, getDocByUid } from 'prismicio'
import { useEffect, useRef, useState } from 'react'

export async function getStaticProps({
  preview,
  params,
  locale,
  locales,
  previewData,
}: GetStaticPropsContext<{ pages: any; auxPageNav: any }>) {
  const config = { locale, locales }
  const client = createClient({ previewData })
  const path = params?.pages.join('.')
  const globalProps = await getGlobalStaticProps(client, locale)
  const translations = await serverSideTranslations(locale ?? 'en-au', [
    'common',
  ])

  if (
    (Array.isArray(path) &&
      path.length === 1 &&
      path[0] === 'homepage' &&
      path[0] === 'shopping_experience') ||
    !path
  ) {
    return { notFound: true }
  }

  // Do not release gallery page for all locale
  if (!Array.isArray(path) && path === 'gallery' && locale !== 'en-au') {
    return { notFound: true }
  }

  let page = null
  let sideNavUid = null
  let sideNav = null
  try {
    page = await getDocByUid('general', path, client, locale)
    sideNavUid = await page?.data?.sideNavigation
    sideNav = await getDocByUid(
      GENERAL_PAGE_SIDE_NAVIGATION_HANDLE,
      sideNavUid ? sideNavUid : AUX_PAGE_NAVIGATION_HANDLE,
      client,
      locale
    )
  } catch (err) {
    console.error(`pages: ${path}`, err)
  }

  if (!page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...globalProps,
      ...translations,
      page,
      sideNav: sideNav?.data || null,
    },
    // revalidate: 86400, // Refresh every 24 hours
  }
}

export async function getStaticPaths({ locales }: GetStaticPathsContext) {
  if (process.env.VERCEL_ENV === 'preview') {
    return {
      paths: [],
      fallback: true,
    }
  }
  const {
    config: { defaultLocale },
  } = commerceConfig

  const pages = await queryAll(
    {
      query: GET_ALL_GENERAL_PAGES_FOR_FEED,
      variables: { lang: defaultLocale },
    },
    'allGenerals'
  )

  const paths = pages
    .map((page) => {
      const uid = page?.node?._meta?.uid
      const pages = uid.split('.')?.filter((s?: string) => s && s !== '')
      return locales?.map((locale) => {
        return { params: { pages }, locale }
      })
    })
    .flat()

  let localeSpecificPaths: any = []
  // locale specific pages
  locales?.forEach(async (l) => {
    if (l != defaultLocale) {
      const localeSpecificPages = await queryAll(
        {
          query: GET_ALL_GENERAL_PAGES_FOR_FEED,
          variables: { lang: l },
        },
        'allGenerals'
      )
      localeSpecificPaths = localeSpecificPages.map((p) => {
        const uid = p?.node?._meta?.uid
        const pages = uid.split('.')?.filter((s?: string) => s && s !== '')
        return { params: { pages }, l }
      })
    }
  })

  return {
    paths: [...paths, ...localeSpecificPaths],
    fallback: true,
  }
}

export default function Pages({
  page,
  sideNav,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  const { asPath, locale } = router || {}
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isMenuTriggersticky, setIsMenuTriggerSticky] = useState(false)
  const menuTriggerRef = useRef<HTMLDivElement>(null)
  const { data } = page || {}
  const {
    Title,
    description,
    slices,
    slices2,
    seoDescription,
    seoImage,
    seoTitle,
    sideNavigation,
    breadcrumbData,
    acknowledgementItems,
  } = data || {}
  const { Links }: any = sideNav || []
  const handleScroll = () => {
    if (menuTriggerRef && menuTriggerRef?.current) {
      setIsMenuTriggerSticky(
        menuTriggerRef.current.getBoundingClientRect().top <= 60
      )
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const handleClosMobileNav = () => {
    setShowMobileNav(false)
  }
  const handleShowMobileNav = () => {
    setShowMobileNav(true)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setShowMobileNav(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  if (router.isFallback) {
    return <Loader title="general-page-loader" />
  }

  if (!data) {
    return <Error code={404} key="pages-404" />
  }
  if (asPath === '/presspage') {
    return (
      <>
        <SeoHead
          title={page?.data?.seoTitle || ''}
          description={page?.data?.seoDescription || ''}
          image={page?.data?.seoImage}
        />
        <SliceZone
          slices={page?.data?.slices}
          components={components}
          context={{ collectionProducts: page?.data?.collectionProducts }}
          key={`${locale}-${asPath}-press`}
        />
      </>
    )
  }
  const mobileAuxSideBarTitle = () => {
    switch (sideNavigation) {
      case STOCKISTS_PAGE_NAVIGATION_HANDLE:
        return 'Region'
      case AUX_PAGE_NAVIGATION_HANDLE:
        return 'Help'
      default:
        return ''
    }
  }
  const currentLinkTitle =
    Links?.find((link: any) => link.link == asPath)?.linkTitle ||
    Links?.[0].linkTitle
  const showTitleDiv = Title || description
  return sideNavigation ? (
    <>
      <SeoHead
        title={seoTitle || Title}
        description={seoDescription || description}
        image={seoImage}
      />
      <div className="bg-brand-light-grey">
        <Container className="py-2 md:pt-6 md:pb-4">
          {breadcrumbData?.length > 0 && (
            <Breadcrumbs crumbs={breadcrumbData} lastCrumH1 />
          )}
        </Container>
        <div className="wrapper pt-12 pb-24 lg:pt-20">
          <div className="flex flex-col gap-y-2 lg:flex-row lg:justify-between">
            {showTitleDiv && (
              <div className="w-full pb-4 text-brand-dark-grey lg:w-160 lg:pb-20">
                {Title && (
                  <h1 className="pb-4 text-mobileh1 lg:text-h1">{Title}</h1>
                )}
                {description && (
                  <p className="text-mobileh4 lg:text-h4">{description}</p>
                )}
              </div>
            )}
            {acknowledgementItems?.length > 0 ? (
              <div className="space-y-1">
                {acknowledgementItems.map(
                  (item: { title: any; value: any }, index: any) => (
                    <AcknowledgementItem
                      key={index}
                      title={item.title || ''}
                      value={item.value || ''}
                    />
                  )
                )}
              </div>
            ) : null}
          </div>

          <div className="w-full">
            <div
              className={cn('flex-column block gap-y-24 lg:flex  lg:flex-row', {
                'gap-x-8': sideNavigation === STOCKISTS_PAGE_NAVIGATION_HANDLE,
                'gap-x-36':
                  sideNavigation === AUX_PAGE_NAVIGATION_HANDLE ||
                  !sideNavigation,
              })}
            >
              <div className="hidden w-full lg:flex lg:w-76">
                <AuxSidebar sideNavigation={sideNavigation} auxItems={Links} />
              </div>
              <div className="sticky top-14 z-30 mb-8 block bg-brand-light-grey py-2 lg:hidden">
                <div
                  className={cn('transition-all duration-150', {
                    'fixed-component-shadow': isMenuTriggersticky,
                  })}
                  ref={menuTriggerRef}
                >
                  <button
                    onClick={handleShowMobileNav}
                    className=" flex w-full justify-between bg-white py-4 pl-4  pr-4 focus:outline-none lg:hidden"
                  >
                    <div className="body-1-bold text-brand-dark-grey">
                      {currentLinkTitle}
                    </div>
                    <Plus />
                  </button>
                </div>
              </div>
              <div className="block lg:hidden">
                <AnimatePresence>
                  {Links && Links?.length > 0 && showMobileNav && (
                    <Sidebar
                      onClose={handleClosMobileNav}
                      slideInFromClassName="slideInFromBottom"
                      classNames="w-full"
                    >
                      <MobileAuxSideBar
                        menuItems={Links}
                        onClose={handleClosMobileNav}
                        title={mobileAuxSideBarTitle()!}
                      />
                    </Sidebar>
                  )}
                </AnimatePresence>
              </div>
              <div className="w-full lg:w-4/5">
                <SliceZone
                  slices={slices}
                  components={components}
                  key={`${locale}-${asPath.replaceAll('/', '')}-slices1`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-brand-white">
        <div className="wrapper w-full">
          <SliceZone
            slices={slices2}
            components={components}
            key={`${locale}-${asPath.replaceAll('/', '')}-slices2`}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <SeoHead
        title={seoTitle || ''}
        description={seoDescription || ''}
        image={seoImage}
      />
      <div className="bg-brand-light-grey">
        <Container className="py-2 md:pt-6 md:pb-4">
          {breadcrumbData?.length > 0 && (
            <Breadcrumbs crumbs={breadcrumbData} lastCrumH1 />
          )}
        </Container>
        <div className="wrapper pt-10 pb-24 lg:pt-20">
          <div className="flex flex-col gap-y-2 lg:flex-row lg:justify-between">
            {showTitleDiv && (
              <div className="w-full pb-4 text-brand-dark-grey lg:w-160 lg:pb-20">
                {Title && (
                  <h1 className="pb-4 text-mobileh1 lg:text-h1">{Title}</h1>
                )}
                {description && (
                  <p className="text-mobileh4 lg:text-h4">{description}</p>
                )}
              </div>
            )}
            {acknowledgementItems?.length > 0 ? (
              <div className="space-y-1">
                {acknowledgementItems.map(
                  (item: { title: any; value: any }, index: any) => (
                    <AcknowledgementItem
                      key={index}
                      title={item.title || ''}
                      value={item.value || ''}
                    />
                  )
                )}
              </div>
            ) : null}
          </div>
          <SliceZone
            slices={slices}
            components={components}
            key={`${locale}-${asPath.replaceAll('/', '')}-slices1`}
          />
        </div>
      </div>
    </>
  )
}

Pages.Layout = Layout
